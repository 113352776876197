﻿(function () {
    window.addEventListener("load",
        function () {
            if (document.querySelector("#trendingslider .trendingslides")) {
                tns({
                    container: '#trendingslider .trendingslides',
                    autoplay: true,
                    autoplayButtonOutput: false,
                    autoplayHoverPause: true,
                    autoplayTimeout: 4000,
                    speed: 900,
                    items: 1,
                    slideBy: 1,
                    nav: false,
                    controls: false,
                    controlsPosition: "bottom",
                    controlsText: ["", ""],

                    responsive: {
                        768: {
                            controls: true,
                        }
                    },
                    onInit: function () {
                        document.querySelector("#trendingslider.trendingslider").classList.add("init");
                    }
                });
            }
        });
})();
