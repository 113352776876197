﻿(function () {
    window.addEventListener('load', function () {

        // Store the window width
        var windowWidth = window.innerWidth

        if (document.querySelector('.slider-hz')) {

            var slideCount = document.querySelectorAll('.slider-hz .g-slide').length;
            var perViewValue = slideCount < 4 ? slideCount : 4;

            if (slideCount === 2) {
                document.querySelector('.slider-hz .g-track').classList.add('limited2');
            }

            if (slideCount === 3) {
                document.querySelector('.slider-hz .g-track').classList.add('limited3');
            }

            var glide = new Glide('.slider-hz', {
                type: 'slider',
                perSwipe: '|',
                perView: perViewValue,  // Set perView based on slide count
                rewind: false,
                bound: true,
                peek: { before: 0, after: 100 },
                gap: 0,
                animationDuration: 1000,
                breakpoints: {
                    1199: {
                        perView: Math.min(3, slideCount),  // Ensure perView does not exceed the slide count
                        peek: { before: 0, after: 80 }
                    },
                    991: {
                        perView: Math.min(3, slideCount),  // Ensure perView does not exceed the slide count
                        peek: { before: 0, after: 60 }
                    },
                    767: {
                        perView: Math.min(2, slideCount),
                        peek: { before: 0, after: 60 }
                    },
                    575: {
                        perView: Math.min(2, slideCount),
                        peek: { before: 0, after: 60 }
                    }
                },
                classes: {
                    swipeable: 'g-swipeable',
                    dragging: 'g-dragging',
                    direction: {
                        ltr: 'g-ltr',
                    },
                    type: {
                        slider: 'g-slider',
                    },
                    slide: {
                        active: 'g-active'
                    },
                    arrow: {
                        disabled: 'g-arrow-disabled'
                    }
                },
            })

            glide.on(['mount.after'],
                function () {
                    document.querySelector('.vehicle-levels .glide').style.opacity = '1';
                }
            );

            window.addEventListener("resize", function () {
                // Check window width has actually changed and it's not just iOS triggering a resize event on scroll
                if (window.innerWidth != windowWidth) {
                    // Update the window width for next time
                    glide.go('<<');
                    windowWidth = window.innerWidth
                }
            })

            glide.mount();

        }
    });
})();