﻿$(document).ready(function () {
    $('.vehicle-counts-more').click(function () {
        if ($(this).hasClass("expanded")) {
            $(this).prevAll(".vehicle-table").find("tr").removeClass('open');
        }

        if ($(this).prevAll(".vehicle-table").find("tbody").hasClass("hide")) {
            $(this).prevAll(".vehicle-table").find("tbody.hide").find("tr:not(.vehicle-derivative)").toggleClass('shown');
        }

        $(this).toggleClass('expanded');

    });

    $('.vehicle-table .expandable').click(function () {
        $(this).toggleClass('open').nextUntil(".expandable").toggleClass('open');
    });

});

$(window).on("resize", function () {
    var captionheight = $('.vehicle-table caption').outerHeight(true)
    $('.vehicle-counts-imagecol').css('margin-top', captionheight);
}).resize();